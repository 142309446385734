import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"

// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"

import Heading from "@/components/general/Heading"
import RacismTable from "@/components/graphs/RacismTable.es"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup.es"
import Dropcap from "@/components/general/Dropcap"
import FooterNav from "@/components/general/FooterNav"

import Motif from "@/components/general/Motif"
import Footer from "@/components/general/Footer.es"
import Share from "@/components/general/Share.es"

import openerText from "@/images/racism/opener-text_spanish.png"
import openerTextSml from "@/images/racism/opener-text-sml_spanish.png"
import shareImg from "@/images/og/og_racism_spanish.jpg"

import dcc from "@/images/racism/dcc_spanish.png"
import dct from "@/images/racism/dct_spanish.png"
import dcr from "@/images/racism/dcr_spanish.png"

import SignupPopup from "@/components/general/SignupPopup.es"

const RacismPage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/es/racism"
                title="Free Markets Destroy | Aprenda cómo los mercados libres destruyen el racismo"
                description="El capitalismo puede ayudar a resolver uno de los desafíos más duraderos de las humanidades, acelerando el cambio social al superar las barreras artificiales que crea el racismo."
                img={shareImg}
                lang="es"
            />

            <Opener
                pageName="racism"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            />

            {
                // ======================================= SECTION 1 =======================================
            }

            <Sizing
                styles={{ float: "right" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginRight: 2,
                            width: 20,
                            marginBottom: 0,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginRight: 2,
                            width: 10,
                            // marginBottom: 1,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <div className="copy--lead">
                    <Dropcap img={dcc}>E</Dropcap>l capitalismo es un sistema inherentemente racista, diseñado por hombres blancos para empoderar a los hombres blancos, ¿verdad?<br />
                    <br />
                    Incorrecto.<br />
                    <br />
                    Una y otra vez el capitalismo ha demostrado ser una poderosa fuerza CONTRA el racismo. Y uno de los mejores ejemplos de esto es un simple catálogo de pedidos por correo.
                </div>
            </Sizing>

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: 2,
                            width: 22,
                            marginBottom: 1,
                            marginTop: 1,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginLeft: 2,
                            width: 22,
                            marginTop: -3,
                            marginBottom: 1,
                        },
                    },
                    // { min: 1200, val: { marginLeft: 8, width: 10 } },
                ]}
            >
                <Image
                    src={"racism_cornucopia"}
                    style={{ width: "100%" }}
                ></Image>
            </Sizing>
            {/*
            <div style={{ float: "left", clear: "left" }}>
                <SignupPopup></SignupPopup>
            </div> */}

            <Sizing
                styles={{ float: "left" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginTop: -4,
                            width: 24,
                        },
                    },

                    {
                        min: 1024,
                        val: {
                            marginTop: -16,
                            width: 24,
                        },
                    },
                ]}
            >
                <ColorChangeBG
                    from={{ r: 255, g: 255, b: 255 }}
                    to={{ r: 233, g: 146, b: 69 }}
                >
                    <Sizing
                        styles={{
                            float: "left",
                            position: "relative",
                            zIndex: 4,
                        }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginLeft: 2,
                                    width: 16,
                                    marginBottom: 1,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    marginLeft: 2,
                                    width: 10,
                                    marginTop: 1,
                                    marginBottom: 2,
                                },
                            },
                            {
                                min: 1200,
                                val: {
                                    marginLeft: 2,
                                    width: 7,

                                    marginBottom: 2,
                                },
                            },
                            // { min: 1200, val: { marginLeft: 8, width: 10 } },
                        ]}
                    >
                        <p className="copy">
                            En el sur de Jim Crow, las leyes racistas obligaron a las familias negras rurales a un acuerdo. Este acuerdo brutal les obligaba a trabajar en la tierra de otra persona por un salario bajo, dejando a estas familias incapaces de comprar nada sin crédito.
                        </p>

                        <p className="copy">
                            Las tiendas locales a menudo negaban el crédito por artículos considerados "demasiado blancos", como artículos de lujo. O las tiendas simplemente prohibirían por completo a los clientes negros de sus tiendas. ¿Cómo podrían las familias negras del sur evitar las leyes racistas y tener acceso a los artículos que necesitaban para dirigir sus vidas?
                        </p>

                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        bottom: -2,
                                        right: -5,
                                        width: 1.5,
                                    },
                                },
                            ]}
                        >
                            <Motif page="racism" id="motif_3" movement={0.02} />
                        </Sizing>

                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        bottom: -3,
                                        right: -4.5,
                                        width: 1.3,
                                    },
                                },
                            ]}
                        >
                            <Motif
                                page="racism"
                                id="motif_2"
                                movement={-0.015}
                            />
                        </Sizing>
                    </Sizing>

                    <div style={{ float: "left", clear: "left" }}>
                        <SignupPopup></SignupPopup>
                    </div>

                    <Sizing
                        styles={{ float: "left", clear: "left" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginLeft: 2,
                                    width: 22,
                                    marginBottom: 1,
                                    marginTop: 1,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    marginLeft: 3,
                                    width: 5,
                                    marginTop: 2,
                                    marginBottom: 1,
                                },
                            },
                            // { min: 1200, val: { marginLeft: 8, width: 10 } },
                        ]}
                    >
                        <Image
                            src={"racism_sowell"}
                            style={{ width: "100%" }}
                        ></Image>

                        <Sizing
                            styles={{ float: "left" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        width: 20,
                                        marginTop: 1,
                                    },
                                },
                                {
                                    min: 1024,
                                    val: {
                                        width: 5,
                                        marginTop: 1,
                                    },
                                },
                                // { min: 1200, val: { marginLeft: 8, width: 10 } },
                            ]}
                        >
                            <Image
                                src={"racism_sowellquote_spanish"}
                                style={{ width: "100%" }}
                            ></Image>
                        </Sizing>

                        <Sizing
                            styles={{ float: "left" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        width: 22,
                                        marginTop: 0.5,
                                    },
                                },
                                {
                                    min: 1024,
                                    val: {
                                        width: 5,
                                        marginTop: 0.5,
                                    },
                                },
                                // { min: 1200, val: { marginLeft: 8, width: 10 } },
                            ]}
                        >
                            <span className="caption">- Thomas Sowell</span>
                        </Sizing>
                    </Sizing>

                    <Sizing
                        styles={{ float: "right", position: "relative" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginRight: 2,
                                    width: 20,
                                    marginBottom: 1,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    marginRight: 2,
                                    width: 10,
                                    marginTop: 0,
                                },
                            },
                            {
                                min: 1200,
                                val: {
                                    marginRight: 5,
                                    width: 9,
                                    marginTop: 4,
                                },
                            },
                            // { min: 1200, val: { marginLeft: 8, width: 10 } },
                        ]}
                    >
                        <p className="copy--lead">
                            Resulta que el racismo le estaba costando dinero a las tiendas físicas. Los dueños de las tiendas estaban suprimiendo artificialmente sus propias ventas cuando rechazaron a los clientes negros. Como explicó el famoso economista Thomas Sowell, "su intolerancia tiene un costo".
                        </p>

                        <p className="copy">
                            Las leyes y prácticas de Jim Crow crearon una enorme base de clientes desatendida, generando involuntariamente una enorme oportunidad de mercado. Los estadounidenses negros estaban ganando dinero y querían gastarlo en productos de alta calidad.
                        </p>
                        <p className="copy">
                            Entra Richard Sears, el Jeff Bezos de su época. Antes de Amazon o Walmart, Sears era el nombre más grande en el comercio minorista estadounidense. Richard Sears en realidad ideó una solución ingeniosa para eludir las leyes racistas: el catálogo de pedidos por correo de Sears.
                        </p>
                        <p className="copy">
                            Popularizado en la década de 1900, el catálogo dio a las familias negras una forma de comprar productos de alta calidad a crédito sin encontrarse con leyes racistas o propietarios de tiendas. Cientos de páginas, entrega rural gratuita y líneas de crédito especiales estaban disponibles específicamente para clientes negros.
                        </p>

                        <Sizing
                            styles={{ position: "absolute", zIndex: 0 }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        bottom: 0,
                                        right: -3,
                                        width: 1,
                                    },
                                },
                            ]}
                        >
                            <Motif page="racism" id="motif_1" movement={0.03} />
                        </Sizing>
                    </Sizing>
                </ColorChangeBG>
            </Sizing>
            <div
                style={{
                    width: "100%",
                    backgroundColor: "rgb(233,146, 69)",
                    float: "left",
                }}
            >
                <Image src={"racism_bbking"} style={{ width: "100%" }}></Image>
            </div>
            <ColorChangeBG
                from={{ r: 233, g: 146, b: 69 }}
                to={{ r: 104, g: 197, b: 184 }}
            >
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginTop: 1,
                                marginLeft: 2,
                                width: 20,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 8,
                                marginTop: 1,
                                marginBottom: 0,
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginLeft: 4,
                                width: 9,
                                marginTop: 1,
                                marginBottom: 0,
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <p className="copy">
                        <Dropcap img={dct}>E</Dropcap>l impacto del catálogo fue particularmente notable en el desarrollo de la música negra. En 1908, el catálogo comenzó a vender guitarras de cuerdas de acero por menos de dos dólares. Las ventas de esas guitarras fueron cruciales para el desarrollo del Delta blues. La leyenda del blues B.B. King aprendió a tocar las cuerdas en una guitarra que compró a Sears.
                    </p>
                    <p className="copy">
                        El catálogo se convirtió rápidamente en la principal forma en que los clientes negros accedían a productos de alta gama y evolucionó a medida que los políticos y las empresas racistas locales intentaron tomar medidas enérgicas, llegando a quemar los catálogos en las calles. Sears incluso dio a los clientes instrucciones sobre cómo sortear a los empleados racistas de la oficina de correos que se negaron a vender a los compradores los sellos que necesitaban para enviar sus pedidos por correo.
                    </p>
                    <p className="copy">
                        Con cada compra, Sears estaba reforzando la noción de que incluso si alguien no se parece a ti, todavía vale la pena servirlo, todavía vale la pena ayudar.
                    </p>
                    <p className="copy">
                        La discriminación excluye a los clientes. Esto crea un fuerte incentivo para que los empresarios sirvan al nuevo mercado creado por leyes o prácticas racistas. Es una dinámica que trabaja todo el tiempo en el mercado abierto.
                    </p>
                    <p className="copy">
                        En el histórico caso de la Corte Suprema, Plessy v. Ferguson, los ferrocarriles hambrientos de ganancias unieron fuerzas con grupos de derechos civiles para desafiar legalmente las leyes de segregación porque las vacantes de asientos artificiales les estaban costando dinero.
                    </p>
                    <p className="copy">
                        O mira las aplicaciones actuales de viajes compartidos como Uber. Uber hizo imposible la discriminación, alimentando la explosión de la aplicación.
                    </p>
                </Sizing>
                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 4,
                                width: 18,
                                marginBottom: 3,
                                marginTop: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 2,
                                width: 4,
                                marginTop: 1,

                                minWidth: "350px",
                            },
                        },
                        // { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <Signup color={["#68C5B8", "#e99245"]}></Signup>
                </Sizing>

                <Sizing
                    styles={{
                        float: "left",
                        clear: "left",
                        position: "relative",
                    }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 4,
                                marginLeft: 4,
                                width: 12,
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute", zIndex: 0 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    top: -5,
                                    right: -6,
                                    width: 4,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_6" movement={0.03} />
                    </Sizing>
                    <Sizing
                        styles={{ position: "absolute", zIndex: 0 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    top: -1,
                                    right: -6,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_10" movement={-0.02} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute", zIndex: 0 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    top: 0,
                                    left: 5.5,
                                    width: 2,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_5" movement={-0.01} />
                    </Sizing>

                    <div style={{ position: "realtive", zIndex: 1 }}>
                        <Heading navy="true" langKey={pageContext.langKey}>
                            DESTRUIR EL RACISMO SISTÉMICO CON LA EDUCACIÓN
                        </Heading>
                    </div>

                    <Sizing
                        styles={{ position: "absolute", zIndex: 2 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    bottom: 0,
                                    right: 0,
                                    width: 1,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_3" movement={0.02} />
                    </Sizing>
                </Sizing>

                <Sizing
                    styles={{ float: "right", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginRight: 2,
                                marginTop: 2,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 3,
                                width: 10,
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ position: "absolute", zIndex: 2 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    top: 3,
                                    left: -9,
                                    width: 4,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_7" movement={-0.02} />
                    </Sizing>
                    <Sizing
                        styles={{ position: "absolute", zIndex: 2 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    bottom: 3,
                                    left: -5,
                                    width: 3,
                                },
                            },
                        ]}
                    >
                        <Motif page="racism" id="motif_9" movement={0.02} />
                    </Sizing>
                    <p className={"copy--lead"}>
                        <Dropcap img={dcr}>E</Dropcap>
                        l racismo todavía existe, y de hecho, es el gobierno y los grupos de intereses especiales los que todavía están liderando la carga.
                    </p>

                    <p className={"copy"}>
                        Este ejemplo está al frente y al centro del sistema de educación pública de Estados Unidos.
Los burócratas escolares, los políticos y los jefes sindicales se adhieren a un sistema que envía a los niños de las minorías a escuelas que fracasan, muchas de las más en los centros de las ciudades. Rechazan las oportunidades de elección de escuela a pesar de que los estudiantes y las familias de minorías son los que más se beneficiarían. Estos opositores a la mejora de los resultados educativos de los niños de las minorías saben muy bien que la investigación es clara.
                    </p>

                    <p className={"copy"}>
                        No necesitas mirar más allá del estudio CREDO de la Universidad de Stanford. Analizó los resultados de las escuelas autónomas (charter) en 27 estados y encontró efectos negativos para algunos estudiantes blancos, con efectos positivos para los estudiantes negros e hispanos en la pobreza. Los estudiantes negros que asistieron a escuelas charter ganaron 36 días de matemáticas y 29 días de lectura. Los estudiantes hispanos ganaron 43 días adicionales de lectura y 50 días de matemáticas.
                    </p>
                </Sizing>
            </ColorChangeBG>

            <RacismTable></RacismTable>

            <Sizing
                styles={{ float: "right", position: "relative" }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            width: 20,
                            marginRight: 2,
                            marginTop: 2,
                            marginBottom: 2,
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            marginRight: 3,
                            width: 10,
                        },
                    },
                ]}
            >
                <Sizing
                    styles={{ position: "absolute", zIndex: 2 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                top: -3,
                                left: -9,
                                width: 3,
                            },
                        },
                    ]}
                >
                    <Motif page="racism" id="motif_9" movement={-0.03} />
                </Sizing>
                <Sizing
                    styles={{ position: "absolute", zIndex: 2 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                top: 0,
                                left: -7,
                                width: 2.5,
                            },
                        },
                    ]}
                >
                    <Motif page="racism" id="motif_10" movement={-0.06} />
                </Sizing>
                <Sizing
                    styles={{ position: "absolute", zIndex: 2 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                top: 3,
                                left: -5,
                                width: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="racism" id="motif_11" movement={-0.1} />
                </Sizing>

                <p className={"copy"}>
                    Fue aún más claro en las áreas urbanas: los estudiantes negros ganaron 59 días de matemáticas y 44 días de lectura. Puede que las leyes de Jim Crow hayan desaparecido, pero hay muchos en posiciones de poder que se oponen a los avances educativos de los niños de las minorías.
                </p>
                <p className={"copy"}>
                    El capitalismo de mercados libre brinda información y oportunidades que crean incentivos para que nos sirvamos unos a otros, independientemente de cómo nos veamos o de lo que creamos. En otras palabras, los mercados libres destruyen el racismo.
                </p>
            </Sizing>

            <Sizing
                style={{
                    width: "100%",
                    float: "left",
                }}
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginBottom: 2,
                        },
                    },
                ]}
            >
                <Image src={"racism_happy"} style={{ width: "100%" }}></Image>
            </Sizing>

            <Sizing
                breaks={[
                    {
                        min: 0,
                        val: {
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: 20,
                            maxWidth: "300px",
                            height: "50px",
                            marginTop: 2,
                            paddingBottom: 5,
                            position: "relative",
                            display: "block",
                        },
                    },
                    {
                        min: 1024,
                        val: {
                            display: "none",
                        },
                    },
                ]}
            >
                <Share></Share>
            </Sizing>

            <Spacing space="0" />
            <FooterNav next={"disease"} previous={"boredom"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default RacismPage
